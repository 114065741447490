"use client";

import React, { FC, useState } from "react";
import Image from "next/image";
import { Button } from "@/src/components/ui/button";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from "@/src/components/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/src/components/ui/form";
import { Input } from "@/src/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/src/components/ui/select";
import { usePostHog } from "posthog-js/react";
import { Loader2, Check } from "lucide-react"; // Import the Check icon
import { useRouter } from 'next/navigation'; // Add this import at the top of your file

interface TalkToSalesProps {
  setIsDialogOpen: (isOpen: boolean) => void;
  showDemoOption?: boolean; // New prop to control whether to show the demo option
}

const formSchema = z.object({
  firstName: z.string().min(2, {
    message: "First name must be at least 2 characters.",
  }),
  lastName: z.string().min(2, {
    message: "Last name must be at least 2 characters.",
  }),
  companyEmail: z.string().email({
    message: "Invalid email address.",
  }),
  role: z.string().nonempty({
    message: "Role is required.",
  }),
  useCase: z.string().nonempty({
    message: "Use case is required.",
  }),
})

interface ProfileFormProps {
  showDemoOption: boolean;
}

const ProfileForm: React.FC<ProfileFormProps> = ({ showDemoOption }) => {
  const router = useRouter();
  const posthog = usePostHog();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isDemoLoading, setIsDemoLoading] = useState(false); // New state for demo button
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      companyEmail: "",
      role: "",
      useCase: "",
    },
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    setIsLoading(true);
    try {
      // Simulate an API call
      await new Promise(resolve => setTimeout(resolve, 2000));
      console.log(values);
      // Add your submission logic here
      setIsSubmitted(true);
      // Don't reset the form after submission
      // Track the event with PostHog
      posthog?.capture('sales_form_submitted', {
        ...values,
        timestamp: new Date().toISOString()
      });
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleBookDemo = async () => {
    setIsDemoLoading(true);
    try {
      // Simulate a delay (e.g., for analytics tracking)
      await new Promise(resolve => setTimeout(resolve, 1000));
      router.push('/demo');
    } catch (error) {
      console.error('Error navigating to demo:', error);
      setIsDemoLoading(false); // Reset loading state if there's an error
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <div className="flex flex-row items-center justify-between">
            <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
                <FormItem>
                <FormLabel>First name</FormLabel>
                <FormControl>
                    <Input placeholder="First" {...field} />
                </FormControl>
                <FormMessage />
                </FormItem>
            )}
            />
            <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
                <FormItem>
                <FormLabel>Last name</FormLabel>
                <FormControl>
                    <Input placeholder="Last" {...field} />
                </FormControl>
                <FormMessage />
                </FormItem>
            )}
            />
        </div>
        <FormField
          control={form.control}
          name="companyEmail"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Company email</FormLabel>
              <FormControl>
                <Input placeholder="Your work email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex flex-row items-center justify-between">
            <FormField
            control={form.control}
            name="role"
            render={({ field }) => (
                <FormItem>
                <FormLabel>Role</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                        <SelectItem value="Individual-contributor">Individual contributor</SelectItem>
                        <SelectItem value="manager">Manager</SelectItem>
                        <SelectItem value="executive">Executive</SelectItem>
                        <SelectItem value="founder">Founder</SelectItem>
                        <SelectItem value="other">Other</SelectItem>
                    </SelectContent>
                </Select>
                <FormMessage />
                </FormItem>
            )}
            />
            <FormField
            control={form.control}
            name="useCase"
            render={({ field }) => (
                <FormItem>
                <FormLabel>Use case</FormLabel>
                <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <FormControl>
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                        <SelectItem value="inventory-management">Inventory Management</SelectItem>
                        <SelectItem value="cross-platform-listing">Cross-Platform Listing</SelectItem>
                        <SelectItem value="sales-analytics">Sales Analytics</SelectItem>
                        <SelectItem value="automated-pricing">Automated Pricing</SelectItem>
                        <SelectItem value="order-fulfillment">Order Fulfillment</SelectItem>
                    </SelectContent>
                </Select>
                <FormMessage />
                </FormItem>
            )}
            />
        </div>
        <div className="space-y-4">
          <Button 
            type="submit" 
            disabled={!form.formState.isValid || isLoading || isSubmitted}
            className="w-full"
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Sending...
              </>
            ) : isSubmitted ? (
              <>
                <Check className="mr-2 h-4 w-4" />
                Sent
              </>
            ) : (
              "Send to sales"
            )}
          </Button>
          {showDemoOption && (
            <>
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <span className="w-full border-t" />
                </div>
                <div className="relative flex justify-center text-xs uppercase">
                  <span className="bg-background px-2 text-muted-foreground">Or</span>
                </div>
              </div>
              <Button
                type="button"
                className="w-full bg-black text-white hover:bg-gray-800"
                onClick={handleBookDemo}
                disabled={isDemoLoading}
              >
                {isDemoLoading ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Loading...
                  </>
                ) : (
                  "Book a demo"
                )}
              </Button>
            </>
          )}
        </div>
      </form>
    </Form>
  )
}

interface TalkToSalesProps {
  setIsDialogOpen: (isOpen: boolean) => void;
  showDemoOption?: boolean;
  children?: React.ReactNode;
}

const TalkToSales: FC<TalkToSalesProps> = ({ setIsDialogOpen, showDemoOption = true, children }) => {
  return (
    <Dialog onOpenChange={setIsDialogOpen}>
      <DialogTrigger asChild>
        {children ? (
          children
        ) : (
          <Button
            variant="secondary"
            className="shadow-[0_4px_4px_rgba(0,0,0,0.08)] w-[200px]" // Added fixed width
          >
            <span className="font-semibold">Talk to sales</span>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent className="max-w-[430px] space-y-8">
        <DialogHeader className="flex flex-row items-start justify-between space-x-4 w-full">
          <div className="flex flex-col space-y-2 text-start max-w-[400px]">
            <DialogTitle>Nearly there!</DialogTitle>
            <DialogDescription>Just a few more details. We will get back to you in 1-2 business days.</DialogDescription>
          </div>
          <DialogClose asChild>
            <button
              className="h-[28px] w-[34px] cursor-pointer items-center justify-center rounded-full bg-lightGray hover:bg-gray-200 transition-all duration-300 text-xl text-black hover:text-gray-700"
            >
              <Image src="/close.png" alt="close" width={100} height={100} className="w-2.5 h-2.5"/>
            </button>
          </DialogClose>
        </DialogHeader>
        <ProfileForm showDemoOption={showDemoOption} />
      </DialogContent>
    </Dialog>
  );
};

export default TalkToSales;